import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SetNickname = () => {
  const [nicknameInput, setNicknameInput] = useState('');
  const navigate = useNavigate();

  const handleNicknameSubmit = () => {
    if (nicknameInput.trim()) {
      localStorage.setItem('nickname', nicknameInput.trim());
      navigate('/chat'); // Navega para o chat após definir o nickname
    } else {
      alert('Por favor, insira um nickname.');
    }
  };

  return (
    <div className="nickname-container">
      <h2>Defina seu Nickname</h2>
      <input
        type="text"
        placeholder="Digite seu nickname"
        value={nicknameInput}
        onChange={(e) => setNicknameInput(e.target.value)}
      />
      <button onClick={handleNicknameSubmit}>Entrar no Chat</button>
    </div>
  );
};

export default SetNickname;
