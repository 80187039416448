import React from 'react';
import ModelViewer from './ModelViewer';
import Chat from './Chat';
import "./App.css"
import SetNickname from "./SetNickname.js"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"

function App() {
  return (
    <Router>
      <div className='App'>
        <ModelViewer />
        <div className="EnvChat">
          <Chat />
        </div>
        <div className="live">
          Live Now <div className="g">•</div>
        </div>
      </div>
      <Routes>
        <Route path="awsdeegsshjkehkjgkfse" element={<SetNickname />} />
      </Routes>
    </Router>
  );
}

export default App;
